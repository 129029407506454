import { Alert, Layout } from 'antd';
import { FREE_ACCOUNT_LIMIT, LOCAL_STORAGE_KEYS } from 'app/Constants';
import Content from 'components/Content';
import Header from 'components/Header';
import HelpModal from 'components/HelpModal';
import QuickstartModal from 'components/QuickstartModal';
import ReAuthDialog from 'components/ReAuthDialog';
import UserDetailsForm from 'components/UserDetailsForm';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setAPIRequestCount, setIsTrialExpired, setIsTrialExpiredModalOpen } from 'redux/userSlice';
import './style.css';
import { selectIsUserFreeAccount } from 'redux/selectors';
import StainingSelectionModal from '../StainingSelectionModal';

const AppLayout = () => {
  const dispatch = useDispatch();
  const isUserFreeAccount = useSelector(selectIsUserFreeAccount);

  useEffect(() => {
    //this should only happen on app open
    if (isUserFreeAccount) {
      let timestampFreeTrialStartDate = window.localStorage.getItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_START_DATE);
      if (timestampFreeTrialStartDate) {
        let daysSinceFreeTrialStart = dayjs().diff(timestampFreeTrialStartDate, 'days') + 1;
        if (daysSinceFreeTrialStart >= FREE_ACCOUNT_LIMIT.TIME_IN_DAYS) {
          batch(() => {
            dispatch(setIsTrialExpired(true));
            dispatch(setIsTrialExpiredModalOpen(true));
          });
        }
      } else {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_START_DATE, dayjs().toISOString());
      }

      let successfulAPIRequestCount =
        parseInt(window.localStorage.getItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_API_REQUEST_COUNT) || '0') || 0;
      if (successfulAPIRequestCount) {
        dispatch(setAPIRequestCount(successfulAPIRequestCount));
        if (successfulAPIRequestCount >= FREE_ACCOUNT_LIMIT.API_REQUESTS) {
          batch(() => {
            dispatch(setIsTrialExpired(true));
            dispatch(setIsTrialExpiredModalOpen(true));
          });
        }
      } else {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_API_REQUEST_COUNT, '0');
      }
    }
  });

  return (
    <Layout style={{ height: '100vh', bottom: 0, overflow: 'hidden', background: '#ffffff' }}>
      <Header />
      <Layout id="content" style={{ width: '100%' }}>
        <Content />
        <ReAuthDialog />
        <Alert
          message="WebIHC is intended for research use only, not for use in diagnostic procedures."
          type="warning"
        />
        <UserDetailsForm />
        <HelpModal />
        <QuickstartModal />
        <StainingSelectionModal />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
