import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal, notification } from 'antd';
import { backendUrl } from 'app/api';
import { captureApplicationError } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  refreshAuthentication,
  selectIsReAuthDialogShown,
  selectLoginTimestamp,
  selectParsedAccessToken,
  selectRefreshToken,
  setIsReAuthDialogShown,
  setUserInfo,
} from 'redux/userSlice';
import logo from 'app/assets/images/mindpeak_logoform_digital_purple_borderless.png';
import './style.css';
import { useAppDispatch } from 'app/hooks';

type ReAuthDialogProps = {
  username: string;
  password: string;
};

const ReAuthDialog = () => {
  const dispatch = useAppDispatch();
  const showReAuthDialog = useSelector(selectIsReAuthDialogShown);
  const loginTimestamp = useSelector(state => selectLoginTimestamp(state));
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const parsedAccessToken = useSelector(selectParsedAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  useEffect(() => {
    if (!parsedAccessToken) return;
    const { exp } = parsedAccessToken;

    const checkAuthState = () => {
      // If there is an "iat" field, the token is potentially refreshable
      if (refreshToken) {
        // ... if the token is not expired
        if (exp * 1000 > Date.now()) {
          // If the user last logged in more than 12 hours ago, we want to refresh it
          if (loginTimestamp + 43200 < Date.now()) {
            dispatch(refreshAuthentication());
          }
          // The token is still valid, or got refreshed. Everything is fine.
        } else {
          // If we reach here, we need to show the re-auth dialog
          dispatch(setIsReAuthDialogShown(true));
        }
      } else {
        // If we reach here, we need to show the re-auth dialog
        dispatch(setIsReAuthDialogShown(true));
      }
    };

    const _onLoadListener = () => checkAuthState();

    const _onVisibilityChangeListener = () => {
      if (document.visibilityState === 'visible') {
        checkAuthState();
      }
    };

    if (document.readyState === 'complete') {
      checkAuthState();
    } else {
      window.addEventListener('load', _onLoadListener);
    }

    window.addEventListener('visibilitychange', _onVisibilityChangeListener);

    return () => {
      // Clean up when unmounting component.
      window.removeEventListener('load', _onLoadListener);
      window.removeEventListener('visibilitychange', _onVisibilityChangeListener);
    };
  }, [dispatch, loginTimestamp, parsedAccessToken, refreshToken]);

  const handleSubmit = (values: ReAuthDialogProps) => {
    setLoggingIn(true);
    const authenticationUrl = backendUrl('authenticate/');

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['username']);
    bodyFormData.append('password', values['password']);

    fetch(authenticationUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(result => {
        setLoggingIn(false);
        dispatch(
          setUserInfo({
            accessToken: result.token,
            groups: result.groups,
            aiToken: result.ai_token,
            refreshToken: result.refresh_token,
            isGTCAccepted: result.gtc_accepted,
            loginTimestamp: Date.now(),
            labId: result.lab_id,
          }),
        );
        dispatch(setIsReAuthDialogShown(false));
        notification['success']({
          message: 'You have successfully signed-in again',
        });
      })
      .catch(err => {
        let errorMessage = 'Error signing in. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }

        setLoggingIn(false);
        setError(true);
        setErrorMessage(errorMessage);
        captureApplicationError(errorMessage);
      });
  };

  return (
    <Modal
      open={showReAuthDialog}
      okText="Log In"
      footer={null}
      style={{ overflowY: 'hidden' }}
      closable={false}
      maskClosable={false}
      keyboard
    >
      <Form className="reauth-form" initialValues={{ remember: true }} onFinish={handleSubmit} layout="vertical">
        <Form.Item style={{ textAlign: 'center' }}>
          <img width={360} src={logo} alt="Mindpeak Logo" style={{ marginBottom: '16px' }} />
        </Form.Item>
        <Form.Item>
          <Alert message="Your session has expired. Please log in again." type="warning" showIcon />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          className="reauth-form-item"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            autoComplete="current-username"
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          className="reauth-form-item"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            size="large"
          />
        </Form.Item>
        <Form.Item className="reauth-form-item" style={{ clear: 'both' }}>
          <Button
            id="reauth-form-login-button"
            htmlType="submit"
            type="primary"
            style={{ width: '100%', marginTop: 16 }}
            loading={loggingIn}
            size="large"
          >
            {loggingIn ? 'Logging In' : 'Log In'}
          </Button>
        </Form.Item>
        {error && <Alert message="Error signing in" description={errorMessage} type="error" showIcon />}
      </Form>
    </Modal>
  );
};

export default ReAuthDialog;
