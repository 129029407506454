import { Alert, Card, Divider, List, Modal, Tag, Typography } from 'antd';
import {
  AI_TYPE_ER,
  AI_TYPE_HER2,
  AI_TYPE_KI67,
  AI_TYPE_KI67_NE,
  AI_TYPE_P53,
  AI_TYPE_PDL1,
  AI_TYPE_PR,
  USER_GROUP,
} from 'app/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserGroups } from 'redux/userSlice';
import {
  selectIsStainingSelectionModalOpen,
  selectUploadImageName,
  setActiveStaining,
  setAIScenario,
  setAIScenariosToDisplay,
  setIsStainingSelectionModalOpen,
} from 'redux/uploadSlice';
import { CheckCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { selectAIScenarioList } from 'redux/selectors';
import { useEffect, useState } from 'react';
import { getStainingFromImageName } from 'app/utils';

const { Text, Title } = Typography;

export default function StainingSelectionModal() {
  const dispatch = useDispatch();
  const isStainingSelectionModalOpen = useSelector(selectIsStainingSelectionModalOpen);
  const userGroups = useSelector(selectUserGroups);
  const aiScenarioList = useSelector(selectAIScenarioList);
  const uploadImageName = useSelector(selectUploadImageName);
  const [selectedStaining, setSelectedStaining] = useState<string>(AI_TYPE_KI67.value);

  let breastList = [AI_TYPE_KI67, AI_TYPE_ER, AI_TYPE_PR];

  if (userGroups.includes(USER_GROUP.KI67NE_ALPHA)) {
    breastList.splice(1, 0, AI_TYPE_KI67_NE);
  }
  if (userGroups.includes(USER_GROUP.HER2_ALPHA)) {
    breastList.unshift(AI_TYPE_HER2);
  }
  if (userGroups.includes(USER_GROUP.PDL1_ALPHA)) {
    breastList.push(AI_TYPE_P53);
  }

  let lungList = [AI_TYPE_PDL1];

  const handleListItemOnClick = (stainingItem: { label: string; value: string }) => {
    setSelectedStaining(stainingItem.value);
  };

  const handleOnSubmit = () => {
    if (selectedStaining === 'other') {
      dispatch(setAIScenario(null));
    } else {
      dispatch(setAIScenario(selectedStaining));
    }
    dispatch(setActiveStaining(selectedStaining));
    dispatch(setAIScenariosToDisplay(aiScenarioList));
    dispatch(setIsStainingSelectionModalOpen(false));
  };

  useEffect(() => {
    if (!isStainingSelectionModalOpen) return;
    const preselectedStaining = getStainingFromImageName(uploadImageName);
    setSelectedStaining(preselectedStaining);
  }, [uploadImageName, isStainingSelectionModalOpen]);

  return (
    <Modal
      open={isStainingSelectionModalOpen}
      closable={false}
      width={1140}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Select"
      onOk={handleOnSubmit}
    >
      <Title level={3}>Select Staining</Title>
      <Text>Please select the staining of the Image.</Text>
      <br />
      <Alert
        type="info"
        showIcon
        icon={<InfoCircleOutlined style={{ color: '#000000' }} />}
        message={
          <>
            If possible we will auto-select the staining. Otherwise, <Text strong>Ki-67</Text> will be used as the
            default.
          </>
        }
        style={{ marginTop: '16px', marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
      />
      <Divider />
      <Title level={4}>Breast</Title>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={breastList}
        renderItem={item => (
          <List.Item>
            <Card
              onClick={() => handleListItemOnClick(item)}
              hoverable
              style={
                selectedStaining === item.value
                  ? { backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }
                  : undefined
              }
            >
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div>
                  <Text>{item.label}</Text>
                  <br />
                  <Text type="secondary">Required magnification:</Text>
                  <div style={{ marginTop: '8px' }}>
                    {item.magnifications.map(magnification => (
                      <Tag>{magnification}</Tag>
                    ))}
                  </div>
                </div>
                {selectedStaining === item.value ? <CheckCircleTwoTone twoToneColor="#6300cc" /> : null}
              </div>
            </Card>
          </List.Item>
        )}
      />

      {userGroups.includes(USER_GROUP.PDL1_ALPHA) ? (
        <>
          <Title level={4}>Lung</Title>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={lungList}
            renderItem={item => (
              <List.Item>
                <Card
                  onClick={() => handleListItemOnClick(item)}
                  hoverable
                  style={
                    selectedStaining === item.value
                      ? { backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }
                      : undefined
                  }
                >
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div>
                      <Text>{item.label}</Text>
                      <br />
                      <Text type="secondary">Required magnification:</Text>
                      <div style={{ marginTop: '8px' }}>
                        {item.magnifications.map(magnification => (
                          <Tag>{magnification}</Tag>
                        ))}
                      </div>
                    </div>
                    {selectedStaining === item.value ? <CheckCircleTwoTone twoToneColor="#6300cc" /> : null}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </>
      ) : null}
      <Divider style={{ marginTop: '16px' }} />
      <Alert
        type="info"
        showIcon
        icon={<InfoCircleOutlined style={{ color: '#000000' }} />}
        message="40x corresponds to 0.25mpp and 20x corresponds to 0.5mpp (microns per pixel)."
        style={{ marginTop: '16px', marginBottom: '16px', backgroundColor: '#ffffff', border: '1px solid #000000' }}
      />
      <Divider />
    </Modal>
  );
}
