import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { backendUrl } from 'app/api';
import { URL_PATH } from 'app/Constants';
import { captureApplicationError } from 'app/utils';
import { validatePassword } from 'components/Authentication/authenticationUtils';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setEmailForAccountUpgrade,
  setPasswordForAccountConfirmation,
  setUsernameForAccountConfirmation,
} from 'redux/userSlice';
import './style.css';

const { Title } = Typography;

type CreateAccountFormProps = {
  uname: string;
  email: string;
  pword: string;
  gtc_accepted: string;
};

const validateGTC = (_: any, value: any) => {
  if (value) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error('You must accept the terms and conditions'));
  }
};

export default function CreateAccountForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (values: CreateAccountFormProps) => {
    setLoggingIn(true);
    const accountCreationURL = backendUrl('initiate_account_create/');

    //Note: using uname and pword instead of username and password to prevent
    // form autofill as autocomplete properties do not work fully in chrome
    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['uname']);
    bodyFormData.append('email', values['email']);
    bodyFormData.append('password', values['pword']);
    bodyFormData.append('gtc_accepted', values['gtc_accepted']);

    fetch(accountCreationURL, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(() => {
        dispatch(setUsernameForAccountConfirmation(values['uname']));
        dispatch(setPasswordForAccountConfirmation(values['pword']));
        dispatch(setEmailForAccountUpgrade(values['email']));

        navigate(`../${URL_PATH.CONFIRM_ACCOUNT}`);
      })
      .catch(err => {
        let errorMessage = 'Error creating account. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }
        setLoggingIn(false);
        setError(true);
        setErrorMessage(errorMessage);
        captureApplicationError(errorMessage);
      });
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required',
    types: {
      email: 'This is not a valid email',
    },
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>Create User Account</Title>
        <Form
          className="create-account-form"
          onFinish={handleSubmit}
          layout="vertical"
          validateMessages={validateMessages}
          autoComplete="new-password"
        >
          <Form.Item label="Username" name="uname" className="create-account-form-item" rules={[{ required: true }]}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              size="large"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            className="create-account-form-item"
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
              size="large"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="pword"
            className="create-account-form-item"
            rules={[{ required: true, validator: validatePassword }]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              size="large"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            className="create-account-form-item"
            name="gtc_accepted"
            valuePropName="checked"
            initialValue={true}
            style={{ marginTop: '16px' }}
            rules={[{ required: true, validator: validateGTC }]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Checkbox id="gtc-confirm-checkbox">
              I have read and agree to the{' '}
              <a target="_blank" href="/general_terms_and_conditions.pdf">
                General Terms and Conditions
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item className="create-account-form-item" style={{ clear: 'both' }}>
            <Button
              id="create-account-button"
              htmlType="submit"
              type="primary"
              style={{ width: '100%', marginTop: 16 }}
              loading={loggingIn}
              size="large"
            >
              Create User Account
            </Button>
          </Form.Item>
          {error && <Alert message="Error creating account in" description={errorMessage} type="error" showIcon />}
        </Form>
      </Col>
    </Row>
  );
}
