import * as Sentry from '@sentry/react';
import {
  AI_TYPE_ER,
  AI_TYPE_HER2,
  AI_TYPE_KI67,
  AI_TYPE_KI67_NE,
  AI_TYPE_P53,
  AI_TYPE_PDL1,
  AI_TYPE_PR,
} from 'app/Constants';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64Url)
      .split('')
      // eslint-disable-next-line prefer-template
      .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join(''),
  );
  return JSON.parse(base64);
};

export const captureApplicationError = (error: string) => {
  // eslint-disable-next-line no-console
  console.error('captured error:', error);
  Sentry.captureException(error);
};

export const getStainingFromImageName = (imageName: string | null) => {
  //Default staining should be Ki67
  if (!imageName) return AI_TYPE_KI67.value;

  const searchableImageName = imageName.trim().toLowerCase();

  if (
    searchableImageName.includes(AI_TYPE_KI67.value) ||
    searchableImageName.includes(AI_TYPE_KI67.label.toLowerCase())
  ) {
    return AI_TYPE_KI67.value;
  }

  if (
    searchableImageName.includes(AI_TYPE_PDL1.value) ||
    searchableImageName.includes(AI_TYPE_PDL1.label.toLowerCase())
  ) {
    return AI_TYPE_PDL1.value;
  }

  if (
    searchableImageName.includes(AI_TYPE_HER2.value) ||
    searchableImageName.includes(AI_TYPE_HER2.label.toLowerCase())
  ) {
    return AI_TYPE_HER2.value;
  }

  if (
    searchableImageName.includes(AI_TYPE_P53.value) ||
    searchableImageName.includes(AI_TYPE_P53.label.toLowerCase())
  ) {
    return AI_TYPE_P53.value;
  }

  if (
    searchableImageName.includes(AI_TYPE_KI67_NE.value) ||
    searchableImageName.includes(AI_TYPE_KI67_NE.label.toLowerCase())
  ) {
    return AI_TYPE_KI67_NE.value;
  }

  if (searchableImageName.includes(AI_TYPE_PR.value) || searchableImageName.includes(AI_TYPE_PR.label.toLowerCase())) {
    return AI_TYPE_PR.value;
  }

  if (searchableImageName.includes(AI_TYPE_ER.value) || searchableImageName.includes(AI_TYPE_ER.label.toLowerCase())) {
    return AI_TYPE_ER.value;
  }

  return AI_TYPE_KI67.value;
};
