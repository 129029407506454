import { createSelector } from '@reduxjs/toolkit';
import { AI_TYPE_HER2, AI_TYPE_KI67_NE, AI_TYPE_OPTIONS, AI_TYPE_P53, AI_TYPE_PDL1, USER_GROUP } from 'app/Constants';
import { selectUserGroups } from 'redux/userSlice';

export const selectAIScenarioList = createSelector([selectUserGroups], userGroups => {
  let aiScenarioList = [...AI_TYPE_OPTIONS];
  if (userGroups.includes(USER_GROUP.KI67NE_ALPHA)) {
    aiScenarioList.splice(1, 0, `${AI_TYPE_KI67_NE.value}:${AI_TYPE_KI67_NE.label}`);
  }
  if (userGroups.includes(USER_GROUP.HER2_ALPHA)) {
    aiScenarioList.unshift(`${AI_TYPE_HER2.value}:${AI_TYPE_HER2.label}`);
  }
  if (userGroups.includes(USER_GROUP.PDL1_ALPHA)) {
    aiScenarioList.unshift(`${AI_TYPE_PDL1.value}:${AI_TYPE_PDL1.label}`);
    aiScenarioList.push(`${AI_TYPE_P53.value}:${AI_TYPE_P53.label}`);
  }
  return aiScenarioList;
});

export const selectIsUserFreeAccount = createSelector([selectUserGroups], userGroups => {
  return (
    userGroups &&
    !userGroups.includes(USER_GROUP.PAID_SINGLE_ACCOUNT) &&
    !userGroups.includes(USER_GROUP.PAID_ENTERPRISE)
  );
});
