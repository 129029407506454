import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFilePondFileInputOpen } from 'redux/uploadSlice';
import { selectIsTrialExpired } from 'redux/userSlice';

export default function ImageUploadButton() {
  const dispatch = useDispatch();
  const isTrialExpired = useSelector(selectIsTrialExpired);

  const openFilePondFileUpload = () => {
    dispatch(setIsFilePondFileInputOpen(true));
  };

  return (
    <Button
      id="image-upload-button"
      type="primary"
      icon={<UploadOutlined />}
      size="middle"
      onClick={openFilePondFileUpload}
      disabled={isTrialExpired}
    >
      Upload Image
    </Button>
  );
}
