import { FileImageOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Modal, Row, Tabs } from 'antd';
import { AI_TYPE_ER, AI_TYPE_HER2, AI_TYPE_KI67, AI_TYPE_PDL1, AI_TYPE_PR, USER_GROUP } from 'app/Constants';
import ModalImageItem from 'components/DemoImagesModal/ModalImageItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDemoImageModalOpen, setIsDemoImageModalOpen } from 'redux/uploadSlice';
import { selectUserGroups } from 'redux/userSlice';

const listOfDemoImages = ['demo_image_1', 'demo_image_2', 'demo_image_3', 'demo_image_4'];

export default function DemoImagesModal() {
  const dispatch = useDispatch();

  const isDemoImageModalOpen = useSelector(selectIsDemoImageModalOpen);
  const userGroups = useSelector(selectUserGroups);

  const showModal = () => {
    dispatch(setIsDemoImageModalOpen(true));
  };

  const handleCancel = () => {
    dispatch(setIsDemoImageModalOpen(false));
  };

  const tabItems = [
    {
      label: 'Ki-67',
      key: 'ki67',
      children: (
        <>
          {' '}
          <Alert
            message={
              <>
                These Demo Images are <strong>20x magnification</strong> - please use the same when uploading Images for
                optimal AI performance.
              </>
            }
            style={{ marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
          />
          <Row gutter={[16, 24]}>
            {listOfDemoImages.map((imageName, index) => (
              <ModalImageItem key={imageName} imageName={imageName} index={index} aiType={AI_TYPE_KI67} />
            ))}
          </Row>
        </>
      ),
    },
    {
      label: 'ER',
      key: 'er',
      children: (
        <>
          <Alert
            message={
              <>
                These Demo Images are <strong>20x magnification</strong> - please use the same when uploading Images for
                optimal AI performance.
              </>
            }
            style={{ marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
          />
          <Row gutter={[16, 24]}>
            {listOfDemoImages.map((imageName, index) => (
              <ModalImageItem key={imageName} imageName={imageName} index={index} aiType={AI_TYPE_ER} />
            ))}
          </Row>
        </>
      ),
    },
    {
      label: 'PR',
      key: 'pr',
      children: (
        <>
          <Alert
            message={
              <>
                These Demo Images are <strong>20x magnification</strong> - please use the same when uploading Images for
                optimal AI performance.
              </>
            }
            style={{ marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
          />
          <Row gutter={[16, 24]}>
            {listOfDemoImages.map((imageName, index) => (
              <ModalImageItem key={imageName} imageName={imageName} index={index} aiType={AI_TYPE_PR} />
            ))}
          </Row>
        </>
      ),
    },
  ];

  if (userGroups.includes(USER_GROUP.PDL1_ALPHA)) {
    tabItems.push({
      label: 'PD-L1',
      key: 'pdl1',
      children: (
        <>
          <Alert
            message={
              <>
                These Demo Images are <strong>20x magnification</strong> - please use the same when uploading Images for
                optimal AI performance.
              </>
            }
            style={{ marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
          />
          <Row gutter={[16, 24]}>
            {listOfDemoImages.map((imageName, index) => (
              <ModalImageItem key={imageName} imageName={imageName} index={index} aiType={AI_TYPE_PDL1} />
            ))}
          </Row>
        </>
      ),
    });
  }

  if (userGroups.includes(USER_GROUP.HER2_ALPHA)) {
    tabItems.push({
      label: 'HER2',
      key: 'her2',
      children: (
        <>
          <Alert
            message={
              <>
                These Demo Images are <strong>40x magnification</strong> - please use the same when uploading Images for
                optimal AI performance.
              </>
            }
            style={{ marginBottom: '16px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
          />
          <Row gutter={[16, 24]}>
            {listOfDemoImages.map((imageName, index) => (
              <ModalImageItem key={imageName} imageName={imageName} index={index} aiType={AI_TYPE_HER2} />
            ))}
          </Row>
        </>
      ),
    });
  }

  return (
    <>
      <Button
        id="view-demo-images-button"
        type="primary"
        icon={<FileImageOutlined />}
        size="middle"
        onClick={showModal}
      >
        View Demo Images
      </Button>
      <Modal title="Demo Images" open={isDemoImageModalOpen} onCancel={handleCancel} width={1140} footer={null}>
        <Divider />
        <Tabs defaultActiveKey="ki67" style={{ marginTop: '-16px' }} items={tabItems} />
      </Modal>
    </>
  );
}
