import { Divider, Progress, Tooltip } from 'antd';
import { FREE_ACCOUNT_LIMIT, LOCAL_STORAGE_KEYS } from 'app/Constants';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectAPIRequestCount, selectIsTrialExpired } from 'redux/userSlice';

export default function FreeUserDetails() {
  const APIRequestCount = useSelector(selectAPIRequestCount);
  const isTrialExpired = useSelector(selectIsTrialExpired);

  let freeApiCallsRemaining = FREE_ACCOUNT_LIMIT.API_REQUESTS - APIRequestCount;
  if (isTrialExpired) {
    freeApiCallsRemaining = 0;
  }

  let timestampFreeTrialStartDate = window.localStorage.getItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_START_DATE) || dayjs();
  let freeTrialDaysRemaining =
    FREE_ACCOUNT_LIMIT.TIME_IN_DAYS - (dayjs().diff(timestampFreeTrialStartDate, 'days') + 1);

  let textColor = 'green';
  if (freeApiCallsRemaining < 5) {
    textColor = 'red';
  } else if (freeApiCallsRemaining < 20) {
    textColor = 'orange';
  }

  const progressPercent = (freeApiCallsRemaining / FREE_ACCOUNT_LIMIT.API_REQUESTS) * 100;

  const hideRequestS = freeApiCallsRemaining !== 1;
  const hideTrialDayS = freeTrialDaysRemaining !== 1;

  return (
    <span>
      <Tooltip title="Demo images are not included in this count">
        You have {freeApiCallsRemaining} image upload{hideRequestS && 's'} left
      </Tooltip>
      <Progress
        percent={progressPercent}
        strokeColor={textColor}
        showInfo={false}
        style={{ paddingLeft: '16px', paddingRight: '8px', width: '240px' }}
      />
      <Divider type="vertical" style={{ borderLeft: '1px solid #ffffff' }} />
      {isTrialExpired ? (
        <span>Your free trial has ended.</span>
      ) : (
        <span>
          Your trial ends in <strong>{freeTrialDaysRemaining}</strong> day{hideTrialDayS && 's'}.
        </span>
      )}
    </span>
  );
}
