import {
  CreditCardOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  RocketOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Button, Layout, Menu, MenuProps } from 'antd';
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_KEYS } from 'app/Constants';
import DemoImagesModal from 'components/DemoImagesModal';
import FreeUserDetails from 'components/Header/FreeUserDetails';
import ImageUploadButton from 'components/ImageUploadButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearReduxStore } from 'redux/store';
import {
  selectAccessToken,
  selectParsedAccessToken,
  setIsHelpModalOpen,
  setIsQuickstartModalOpen,
  setIsUserDetailsFormOpen,
} from 'redux/userSlice';
import MindPeakAppLogo from 'app/assets/images/mindpeak_web_ihc_logoform_digital_light_borderless_48.png';
import './style.css';
import { selectIsUserFreeAccount } from 'redux/selectors';
import { setIsUploadingImage, setUploadImageId } from '../../redux/uploadSlice';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserFreeAccount = useSelector(selectIsUserFreeAccount);
  const accessToken = useSelector(state => selectAccessToken(state));
  const parsedAccessToken = useSelector(selectParsedAccessToken);
  const { username } = parsedAccessToken;

  const logOut = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    dispatch(clearReduxStore());
    navigate('/');
  };

  const handleUpgradeButtonOnClick = () => {
    dispatch(setIsUserDetailsFormOpen(true));
  };

  if (!window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICATION_FIRST_USE)) {
    dispatch(setIsQuickstartModalOpen(true));
  }

  const openHelpModal = () => {
    dispatch(setIsHelpModalOpen(true));
  };

  const openQuickstartModal = () => {
    dispatch(setIsQuickstartModalOpen(true));
  };

  Sentry.configureScope(scope => {
    if (!accessToken) {
      logOut();
    }

    scope.setUser({
      username,
    });
  });

  let controlMenuItems: MenuProps['items'] = [
    {
      key: 'imageUploadButton',
      label: <ImageUploadButton />,
    },
    {
      key: 'demoImagesModal',
      label: <DemoImagesModal />,
    },
  ];

  let infoMenuItems: MenuProps['items'] = [
    {
      key: 'username',
      label: username,

      icon: <SettingOutlined />,
      children: [
        {
          key: 'quickstart',
          label: 'Quickstart',
          icon: <RocketOutlined />,
          onClick: openQuickstartModal,
        },
        {
          key: 'help',
          label: 'Help',
          icon: <InfoCircleOutlined />,
          onClick: openHelpModal,
        },
        { type: 'divider' },
        {
          key: 'contactUs',
          label: (
            <a href="https://www.mindpeak.ai/contact/" target="_blank" rel="noopener noreferrer">
              Contact us
            </a>
          ),
        },
        { type: 'divider' },
        {
          key: 'logOut',
          label: 'Log Out',
          icon: <LogoutOutlined />,
          onClick: logOut,
        },
      ],
    },
  ];

  if (isUserFreeAccount) {
    controlMenuItems.push({
      key: 'freeUserDetails',
      label: <FreeUserDetails />,
    });
    controlMenuItems.push({
      key: 'upgradeAccount',
      label: (
        <Button
          id="upgrade-account-button"
          type="primary"
          icon={<CreditCardOutlined />}
          onClick={handleUpgradeButtonOnClick}
        >
          Upgrade
        </Button>
      ),
    });
  }

  return (
    <Layout.Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Link
        to="/"
        onClick={() => {
          dispatch(setIsUploadingImage(false));
          dispatch(setUploadImageId(null));
        }}
      >
        <img src={MindPeakAppLogo} style={{ margin: '8px 24px 16px 0' }} height={48} alt="Mindpeak IMS Logo" />
      </Link>
      <div style={{ minWidth: 0, flex: 'auto', justifyContent: 'space-between', display: 'flex' }}>
        <Menu
          selectable={false}
          items={controlMenuItems}
          theme="dark"
          mode="horizontal"
          style={{ minWidth: 0, flex: 'auto' }}
        />
        <Menu
          selectable={false}
          items={infoMenuItems}
          theme="dark"
          mode="horizontal"
          style={{ minWidth: 0, flex: 'auto', justifyContent: 'end' }}
        />
      </div>
    </Layout.Header>
  );
}

export default Header;
