import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import Router from './app/router';
import './index.css';
import { version } from 'version';
import { ConfigProvider } from 'antd';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    // Safely ignoring ResizeObserver errors: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: version.version,
  });
}

if (window.Cypress) {
  window.store = store;
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6300cc',
          fontFamily:
            "'Barlow', 'Times New Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n" +
            "    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          colorLink: '#6300cc', //#1677ff
          colorLinkActive: '#4b00a6', //	#0958d9
          colorLinkHover: '#8123d9', //	#69b1ff
        },
      }}
    >
      <Router />
    </ConfigProvider>
  </Provider>,
);
