import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd';
import { backendUrl } from 'app/api';
import { URL_PATH } from 'app/Constants';
import { captureApplicationError } from 'app/utils';
import { validatePassword } from 'components/Authentication/authenticationUtils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

type ResetPasswordFormProps = {
  username: string;
};

type ConfirmResetPasswordFormProps = {
  username: string;
  confirmation_code: string;
  new_password: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [usernameForConfirmation, setUsernameForConfirmation] = useState('');

  const returnToLogin = () => {
    navigate(`../${URL_PATH.LOGIN}`);
  };

  const handleInitiatePasswordReset = (values: ResetPasswordFormProps) => {
    setIsLoading(true);
    const initiatePasswordResetUrl = backendUrl('initiate_password_reset/');

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['username'].trim());

    fetch(initiatePasswordResetUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(() => {
        setIsVerificationCodeSent(true);
        setIsLoading(false);
        setErrorMessage('');
        setIsAlertShown(false);
        setUsernameForConfirmation(values['username'].trim());
      })
      .catch(err => {
        let errorMessage = 'Error requesting password reset verification code. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }
        captureApplicationError(`error requesting password reset verification code - ${errorMessage}`);
        setIsLoading(false);
        setErrorMessage(errorMessage);
        setIsAlertShown(true);
      });
  };

  const handleConfirmPasswordReset = (values: ConfirmResetPasswordFormProps) => {
    setIsLoading(true);

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', usernameForConfirmation);
    bodyFormData.append('confirmation_code', values['confirmation_code']);
    bodyFormData.append('password', values['new_password']);

    const confirmPasswordResetUrl = backendUrl('confirm_password_reset/');
    fetch(confirmPasswordResetUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(() => {
        setIsLoading(false);
        setIsAlertShown(true);
        setErrorMessage('');
        setTimeout(() => {
          navigate(`../`);
        }, 4000);
      })
      .catch(err => {
        let errorMessage = 'Error resetting password. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }
        captureApplicationError(`error resetting password - ${errorMessage}`);
        setIsLoading(false);
        setErrorMessage(errorMessage);
        setIsAlertShown(true);
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>Reset Password</Title>
        <Form
          className="reset-password-form"
          initialValues={{ remember: true }}
          onFinish={isVerificationCodeSent ? handleConfirmPasswordReset : handleInitiatePasswordReset}
          layout="vertical"
        >
          <Form.Item style={{ paddingTop: '10px' }}>
            <Typography>
              {!isVerificationCodeSent
                ? 'Please enter your username so we can send you a verification code via email.'
                : 'You should now have received a verification code via email. Please enter the code here and specify a new password.'}
            </Typography>
          </Form.Item>
          {!isVerificationCodeSent ? (
            <Form.Item
              label="Username"
              name="username"
              className="confirm-account-form-item"
              rules={[{ required: true, message: 'Username is required to reset password' }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
                size="large"
                autoComplete="new-password"
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Verification Code"
                name="confirmation_code"
                className="confirm-account-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Please input your verification code from the email.',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Verification Code"
                  size="large"
                  disabled={isAlertShown && !errorMessage}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                className="create-account-form-item"
                rules={[{ required: true, validator: validatePassword }]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="New Password"
                  size="large"
                  autoComplete="new-password"
                />
              </Form.Item>
            </>
          )}
          <Button
            data-test="return-to-login"
            style={{ padding: 0, float: 'right' }}
            type="link"
            onClick={returnToLogin}
          >
            Return to login
          </Button>
          <Form.Item style={{ clear: 'both' }}>
            <Button
              id="reset-password-button"
              htmlType="submit"
              type="primary"
              style={{ width: '100%', marginTop: 16 }}
              loading={isLoading}
              size="large"
              disabled={isAlertShown && !errorMessage}
            >
              {!isVerificationCodeSent ? 'Request verification code' : 'Confirm new password'}
            </Button>
          </Form.Item>
          {isAlertShown &&
            (errorMessage ? (
              <Alert message="Error resetting password" description={errorMessage} type="error" showIcon />
            ) : (
              <Alert
                message="Successfully reset password"
                description="You may now log in with your new password."
                type="success"
                showIcon
              />
            ))}
        </Form>
      </Col>
    </Row>
  );
}
