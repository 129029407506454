import { ReactNode } from 'react';

export default function PasswordErrorList({ passwordErrors }: { passwordErrors: Array<string> }) {
  let passwordErrorList: Array<ReactNode> = [];

  passwordErrors.forEach(item => {
    switch (item) {
      case 'min':
        passwordErrorList.push(<li>be at least 8 characters</li>);
        break;
      case 'digits':
        passwordErrorList.push(<li>contain at least 1 digit</li>);
        break;
      case 'symbols':
        passwordErrorList.push(<li>contain at least 1 special character</li>);
        break;
      case 'uppercase':
        passwordErrorList.push(<li>contain at least 1 UPPERCASE character</li>);
        break;
      case 'lowercase':
        passwordErrorList.push(<li>contain at least 1 lowercase</li>);
        break;
      default:
        break;
    }
  });

  return (
    <p>
      Your password must: <ul>{passwordErrorList}</ul>
    </p>
  );
}
