import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl, postRequestPromise } from 'app/api';
import { FREE_ACCOUNT_LIMIT, LOCAL_STORAGE_KEY, LOCAL_STORAGE_KEYS } from 'app/Constants';
import { parseJwt } from 'app/utils';
import { AppDispatch, RootState } from './store';

const selectPersistentState = (sliceName: string) => {
  const persistentSliceJSON = localStorage.getItem(sliceName);
  if (!persistentSliceJSON) return {};
  return JSON.parse(persistentSliceJSON);
};

export const refreshAuthentication = createAsyncThunk<
  any,
  void,
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('user/refreshAuthentication', async (payload, { rejectWithValue, getState }) => {
  const authenticationUrl = backendUrl('refresh_authentication/');

  const { accessToken, aiToken, refreshToken } = getState().user;

  let bodyFormData = new window.FormData();
  bodyFormData.append('access_token', accessToken);
  bodyFormData.append('id_token', aiToken);
  bodyFormData.append('refresh_token', refreshToken);

  const response = await postRequestPromise(authenticationUrl, bodyFormData);
  if (response.status === 404 || response.status === 500) {
    return rejectWithValue(null);
  }
  return response.json();
});

interface UserSlice {
  isReAuthDialogShown: boolean;
  isTrialExpired: boolean;
  isTrialExpiredModalOpen: boolean;
  isUserDetailsFormOpen: boolean;
  APIRequestCount: number;
  usernameForAccountConfirmation: string;
  passwordForAccountConfirmation: string;
  emailForAccountUpgrade: string;
  isHelpModalOpen: boolean;
  isQuickstartModalOpen: boolean;
  accessToken: string | null;
  groups: Array<string>;
  allowToAnnotate: boolean;
  disableHotspotPulling: boolean;
  webGLEnabled: boolean;
  aiToken: string | null;
  refreshToken: string | null;
  isScoreInputEnabled: boolean;
  loginTimestamp: number;
  labId: number;
}

const initialState = {
  isReAuthDialogShown: false,
  isTrialExpired: false,
  isTrialExpiredModalOpen: false,
  isUserDetailsFormOpen: false,
  APIRequestCount: 0,
  usernameForAccountConfirmation: '',
  passwordForAccountConfirmation: '',
  emailForAccountUpgrade: '',
  isHelpModalOpen: false,
  isQuickstartModalOpen: false,
  accessToken: null,
  groups: [],
  allowToAnnotate: false,
  disableHotspotPulling: false,
  webGLEnabled: false,
  aiToken: null,
  refreshToken: null,
  isScoreInputEnabled: false,
  loginTimestamp: 0,
  labId: 0,
} as UserSlice;

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...initialState,
    ...selectPersistentState(LOCAL_STORAGE_KEY),
  },
  reducers: {
    setIsReAuthDialogShown: (state, action) => {
      state.isReAuthDialogShown = action.payload;
    },
    setIsTrialExpired: (state, action) => {
      state.isTrialExpired = action.payload;
    },
    setIsTrialExpiredModalOpen: (state, action) => {
      state.isTrialExpiredModalOpen = action.payload;
    },
    setIsUpgradeChoiceModalOpen: (state, action) => {
      state.isUpgradeChoiceModalOpen = action.payload;
    },
    setIsUserDetailsFormOpen: (state, action) => {
      state.isUserDetailsFormOpen = action.payload;
    },
    setAPIRequestCount: (state, action) => {
      state.APIRequestCount = action.payload;
    },
    incrementAPIRequestCount: state => {
      state.APIRequestCount += 1;
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_API_REQUEST_COUNT, state.APIRequestCount.toString());
      if (state.APIRequestCount >= FREE_ACCOUNT_LIMIT.API_REQUESTS) {
        state.isTrialExpired = true;
        state.isTTrialExpiredModalOpen = true;
      }
    },
    setUsernameForAccountConfirmation: (state, action) => {
      state.usernameForAccountConfirmation = action.payload;
    },
    setPasswordForAccountConfirmation: (state, action) => {
      state.passwordForAccountConfirmation = action.payload;
    },
    setEmailForAccountUpgrade: (state, action) => {
      state.emailForAccountUpgrade = action.payload;
    },
    setIsHelpModalOpen: (state, action) => {
      state.isHelpModalOpen = action.payload;
    },
    setIsQuickstartModalOpen: (state, action) => {
      state.isQuickstartModalOpen = action.payload;
    },
    setUserInfo: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.groups = action.payload.groups;
      state.aiToken = action.payload.aiToken;
      state.refreshToken = action.payload.refreshToken;
      state.loginTimestamp = action.payload.loginTimestamp;
      state.isGTCAccepted = action.payload.isGTCAccepted;
      state.labId = action.payload.labId;
    },
  },
  extraReducers: builder => {
    builder.addCase(refreshAuthentication.fulfilled, (state, action) => {
      if (!action.payload || Object.keys(action.payload).length === 0) {
        return;
      }

      let newUserInfo = {
        accessToken: action.payload.token,
        aiToken: action.payload.aiToken,
        loginTimestamp: action.payload.loginTimestamp,
      };
      // Need to assign property by property to get reducer to work
      state.accessToken = newUserInfo.accessToken;
      state.aiToken = newUserInfo.aiToken;
      state.loginTimestamp = newUserInfo.loginTimestamp;
      const existingUserData = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (existingUserData) {
        newUserInfo = {
          ...JSON.parse(existingUserData),
          ...newUserInfo,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newUserInfo));
      }
    });
    builder.addCase(refreshAuthentication.rejected, state => {
      state.isReAuthDialogShown = true;
    });
  },
});

export const {
  setIsReAuthDialogShown,
  setIsTrialExpired,
  setIsTrialExpiredModalOpen,
  setIsUserDetailsFormOpen,
  setAPIRequestCount,
  incrementAPIRequestCount,
  setUsernameForAccountConfirmation,
  setPasswordForAccountConfirmation,
  setEmailForAccountUpgrade,
  setIsHelpModalOpen,
  setIsQuickstartModalOpen,
  setUserInfo,
} = userSlice.actions;

export const selectAccessToken = (state: RootState) => state.user.accessToken;

export const selectParsedAccessToken = (state: RootState) => {
  const { accessToken } = state.user;
  if (!accessToken) return null;
  return parseJwt(accessToken);
};
export const selectUsernameForAccountConfirmation = (state: RootState) => state.user.usernameForAccountConfirmation;
export const selectPasswordForAccountConfirmation = (state: RootState) => state.user.passwordForAccountConfirmation;
export const selectRefreshToken = (state: RootState) => state.user.refreshToken;
export const selectLoginTimestamp = (state: RootState) => state.user.loginTimestamp;
export const selectLabId = (state: RootState) => state.user.labId;
export const selectUserGroups = (state: RootState) => state.user.groups;
export const selectAPIRequestCount = (state: RootState) => state.user.APIRequestCount;
export const selectIsTrialExpired = (state: RootState) => state.user.isTrialExpired;
export const selectIsHelpModalOpen = (state: RootState) => state.user.isHelpModalOpen;
export const selectIsQuickstartModalOpen = (state: RootState) => state.user.isQuickstartModalOpen;
export const selectIsReAuthDialogShown = (state: RootState) => state.user.isReAuthDialogShown;
export const selectIsUserDetailsFormOpen = (state: RootState) => state.user.isUserDetailsFormOpen;
export const selectEmailForAccountUpgrade = (state: RootState) => state.user.emailForAccountUpgrade;
export default userSlice.reducer;
