import { Alert, Col, Divider, Form, Input, Modal, notification, Row, Select, Spin } from 'antd';
import { captureApplicationError } from 'app/utils';
import { send } from 'emailjs-com';
//@ts-ignore
import libphonenumbers from 'libphonenumbers';
import React, { useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import { selectEmailForAccountUpgrade, selectIsUserDetailsFormOpen, setIsUserDetailsFormOpen } from 'redux/userSlice';
import './style.css';

type UserDetailsFormProps = {
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  contactNumber: string;
};

const options = [
  { key: 'female', label: 'Female', value: 'Female' },
  { key: 'male', label: 'Male', value: 'Male' },
  { key: 'Other', label: 'Other', value: 'Other' },
  { key: 'prefer_not_to_say', label: 'Prefer not to say', value: 'Prefer not to say' },
];

export default function UserDetailsForm() {
  const dispatch = useDispatch();
  const isUserDetailsFormOpen = useSelector(selectIsUserDetailsFormOpen);
  const emailForAccountUpgrade = useSelector(selectEmailForAccountUpgrade);

  const [form] = Form.useForm();
  const phoneNumberRef = useRef<any>(null);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isSubmissionError, setIsSubmissionError] = useState(false);

  const handleCancel = () => {
    dispatch(setIsUserDetailsFormOpen(false));
  };

  const handleSubmit = () => {
    form.validateFields().then(values => {
      onCreate(values);
    });
  };

  const onCreate = (values: UserDetailsFormProps) => {
    const emailRequestData = {
      user_title: values.title,
      user_first_name: values.firstName,
      user_last_name: values.lastName,
      user_gender: values.gender,
      user_email: values.email,
      user_phone_number: values.contactNumber,
    };

    setIsFormSubmitting(true);
    send('gmail', 'user_upgrade', emailRequestData, process.env.REACT_APP_EMAIL_JS_USER_ID).then(
      () => {
        setIsFormSubmitting(false);
        batch(() => {
          notification['success']({
            message: 'Form submitted successfully',
            description: "We'll be in contact soon. In the meantime, you can use Mindpeak WebIHC as a free user.",
          });
          dispatch(setIsUserDetailsFormOpen(false));
        });
      },
      error => {
        captureApplicationError(`error sending email ${error.toString()}`);
        setIsFormSubmitting(false);
        setIsSubmissionError(true);
      },
    );
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required',
    types: {
      email: 'This is not a valid email',
    },
  };

  const initialValues = {
    email: emailForAccountUpgrade,
  };

  const validateContactNumber = (_: any, value: any) => {
    if (!phoneNumberRef.current) return;
    const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(value, phoneNumberRef.current.props.country);
      return phoneUtil.isValidNumber(number) ? Promise.resolve() : Promise.reject(new Error('Invalid contact number'));
    } catch (err) {
      return Promise.reject(new Error('Invalid contact number'));
    }
  };

  return (
    <Modal
      title="Upgrade your Account"
      open={isUserDetailsFormOpen}
      onCancel={handleCancel}
      width={1140}
      onOk={handleSubmit}
      okText="Submit"
    >
      <Divider />
      <Spin tip="Submitting account upgrade request..." spinning={isFormSubmitting}>
        <Alert
          type="info"
          message={
            <>
              In order to upgrade your account we require the following additional details. <br />
              Once you submit this form we will contact you shortly.
            </>
          }
          style={{ marginBottom: '24px', backgroundColor: '#f6e6ff', border: '1px solid #be74f2' }}
        />
        <Form
          form={form}
          name="user_details"
          layout="vertical"
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item className="user-details-form-item" label="Title" name="title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="user-details-form-item"
                label="First Name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="user-details-form-item"
                label="Last Name"
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item className="user-details-form-item" label="Gender" name="gender">
                <Select>
                  {options.map(option => (
                    <Select.Option key={option.key} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="user-details-form-item"
            label="Email"
            name="email"
            rules={[{ type: 'email', required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="user-details-form-item"
            label="Contact Number"
            name="contactNumber"
            rules={[{ required: true, validator: validateContactNumber }]}
          >
            <PhoneInput
              //@ts-ignore
              id="contact-number"
              ref={phoneNumberRef}
              containerClass={'error'}
              style={{ padding: 0 }}
              country={'de'}
              placeholder=""
              preferredCountries={['de', 'gb', 'us']}
              enableSearch={true}
              isValid={(value, country) => {
                const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
                try {
                  //@ts-ignore
                  const number = phoneUtil.parseAndKeepRawInput(value, country.iso2);
                  return phoneUtil.isValidNumber(number);
                } catch (err) {
                  return false;
                }
              }}
            />
          </Form.Item>
        </Form>
        {isSubmissionError && (
          <>
            <Divider />
            <Alert
              type="error"
              message="Error"
              description={
                <span>
                  There was an issue submitting your request. Please try again later or{' '}
                  <a href="https://www.mindpeak.ai/contact/" target="_blank" rel="noopener noreferrer">
                    contact us directly
                  </a>
                  .
                </span>
              }
              showIcon
            />
          </>
        )}
      </Spin>
    </Modal>
  );
}
