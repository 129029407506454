import { Card, Col } from 'antd';
import { uploadImage } from 'app/api';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  setActiveStaining,
  setAIScenario,
  setAIScenariosToDisplay,
  setIsDemoImageModalOpen,
  setIsUploadingImage,
} from 'redux/uploadSlice';
import { selectAccessToken } from 'redux/userSlice';

export default function ModalImageItem({
  imageName,
  index,
  aiType,
}: {
  imageName: string;
  index: number;
  aiType: AIType;
}) {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  const getAPIResultFromModalItem = (imageName: string, aiType: AIType, imageDisplayName: string) => {
    let imageFile = require(`app/assets/images/demo/${aiType.value}/${imageName}.png`);

    dispatch(setIsUploadingImage(true));

    fetch(imageFile)
      .then(response => {
        return response.blob();
      })
      .then(imageBlob => {
        const imgFile = new File([imageBlob], `${imageName}.png`, { type: 'image/png' });
        uploadImage(dispatch, imgFile, imageDisplayName, accessToken, null, null);
      })
      .then(() => {
        batch(() => {
          dispatch(setIsDemoImageModalOpen(false));
          dispatch(setActiveStaining(aiType.value));
          dispatch(setAIScenario(aiType.value));
          dispatch(setAIScenariosToDisplay([`${aiType.value}:${aiType.label}`]));
        });
      });
  };

  const imageThumbnail = require(`app/assets/images/demo/${aiType.value}/thumbnails/${imageName}.jpg`);
  const imageDisplayName = `${aiType.label} Demo Image #${index + 1}`;

  return (
    <Col className="gutter-row" span={6}>
      <Card
        id={imageName}
        className="grid-item"
        hoverable
        onClick={() => getAPIResultFromModalItem(imageName, aiType, imageDisplayName)}
        cover={<img className="thumbnail" alt={imageName} src={imageThumbnail} />}
      >
        <Card.Meta title={`${aiType.label} Demo Image #${index + 1}`} />
      </Card>
    </Col>
  );
}
