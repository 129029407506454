import PasswordValidator from 'password-validator';
import PasswordErrorList from './PasswordErrorList';

export const validatePassword = (_: any, value: any) => {
  let schema = new PasswordValidator();
  schema.min(8).has().digits().has().symbols().has().uppercase().has().lowercase();

  let passwordErrors: boolean | Array<any> = schema.validate(value, { list: true });

  if (passwordErrors && typeof passwordErrors !== 'boolean' && passwordErrors.length > 0) {
    return Promise.reject(<PasswordErrorList passwordErrors={passwordErrors} />);
  }

  return Promise.resolve();
};
