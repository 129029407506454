import { notification } from 'antd';
import { uploadImage } from 'app/api';
import { FREE_ACCOUNT_LIMIT, LOCAL_STORAGE_KEYS } from 'app/Constants';
import { captureApplicationError } from 'app/utils';
import UploadLabel from 'components/ImageUploader/UploadLabel';
import dayjs from 'dayjs';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { useEffect, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { batch, useDispatch, useSelector } from 'react-redux';
import { selectIsUserFreeAccount } from 'redux/selectors';
import {
  selectIsFilePondInputOpen,
  selectUploadImageId,
  setActiveStaining,
  setIsFilePondFileInputOpen,
  setIsStainingSelectionModalOpen,
  setIsUploadingImage,
} from 'redux/uploadSlice';
import {
  incrementAPIRequestCount,
  selectAccessToken,
  selectIsTrialExpired,
  setAPIRequestCount,
  setIsTrialExpired,
  setIsTrialExpiredModalOpen,
} from 'redux/userSlice';
import './style.css';
import { createRoot } from 'react-dom/client';
import { FilePondFile } from 'filepond';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

// Use CustomFilePondErrorDescription instead of FilePondErrorDescription as types are incorrect.
// https://github.com/pqina/filepond/issues/756
type CustomFilePondErrorDescription = {
  main: string;
  sub: string;
};

export default function ImageUploader() {
  const dispatch = useDispatch();
  const uploadImageId = useSelector(selectUploadImageId);
  const isTrialExpired = useSelector(selectIsTrialExpired);
  const isFilePondFileInputOpen = useSelector(selectIsFilePondInputOpen);
  const isUserFreeAccount = useSelector(selectIsUserFreeAccount);
  const accessToken = useSelector(selectAccessToken);

  const [files, setFiles] = useState([]);

  const fileRef = useRef<FilePond | null>(null);
  const handleAddImage = (error: CustomFilePondErrorDescription | null, file: FilePondFile) => {
    /*
     * When pasting a file, the clipboard will occasionally be empty and paste a 'null' file.
     * This causing an internal error in FilePond. This check prevents that for now.
     * */
    if (file === null) {
      alert('There was an issue pasting the file. Please refresh the page and try again.');
      return null;
    }

    if (!error) {
      dispatch(setActiveStaining(null));

      dispatch(setIsStainingSelectionModalOpen(true));

      dispatch(setIsUploadingImage(true));

      uploadImage(
        dispatch,
        file.file,
        file.file.name,
        accessToken,
        () => {
          if (isUserFreeAccount) {
            dispatch(incrementAPIRequestCount());
          }
        },
        () => {
          setFiles([]);
        },
      );
    } else {
      // @ts-ignore
      let errorMessage = error.main;
      if (errorMessage.includes('File is of invalid type')) {
        errorMessage = 'Image should be .jpg/jpeg, .png or .tif/tiff';
      }

      notification['error']({
        message: 'Error uploading the image.',
        description: errorMessage,
      });
      captureApplicationError(`Error uploading image - ${errorMessage} / ${error.sub}`);
      setFiles([]);
    }
  };

  useEffect(() => {
    if (isUserFreeAccount) {
      let timestampFreeTrialStartDate = window.localStorage.getItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_START_DATE);
      if (timestampFreeTrialStartDate) {
        let daysSinceFreeTrialStart = dayjs().diff(timestampFreeTrialStartDate, 'days') + 1;
        if (daysSinceFreeTrialStart >= FREE_ACCOUNT_LIMIT.TIME_IN_DAYS) {
          batch(() => {
            dispatch(setIsTrialExpired(true));
            dispatch(setIsTrialExpiredModalOpen(true));
          });
        }
      } else {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_START_DATE, dayjs().toISOString());
      }

      let successfulAPIRequestCount =
        parseInt(window.localStorage.getItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_API_REQUEST_COUNT) || '0') || 0;
      if (successfulAPIRequestCount) {
        dispatch(setAPIRequestCount(successfulAPIRequestCount));
        if (successfulAPIRequestCount >= FREE_ACCOUNT_LIMIT.API_REQUESTS) {
          batch(() => {
            setIsTrialExpired(true);
            setIsTrialExpiredModalOpen(true);
          });
        }
      } else {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.FREE_ACCOUNT_API_REQUEST_COUNT, '0');
      }
    }
  });

  useEffect(() => {
    //This is a 'hack' to display a React Component as a FilePond label rather than just text/a long html string
    //This replaces the existing label

    const filePondDropLabelElement = document.querySelector('.filepond--drop-label');
    if (!filePondDropLabelElement) return;

    const root = createRoot(filePondDropLabelElement);
    root.render(<UploadLabel isTrialExpired={isTrialExpired} />);

    //This re-adds the onclick method to open the file browser
    filePondDropLabelElement.addEventListener('click', () => {
      if (!fileRef.current) return;
      fileRef.current.browse();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFilePondFileInputOpen && fileRef.current) {
      fileRef.current.browse();
      dispatch(setIsFilePondFileInputOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilePondFileInputOpen]);

  return (
    <div style={{ display: uploadImageId ? 'none' : '', height: '100%' }}>
      <FilePond
        ref={fileRef}
        allowMultiple={false}
        allowBrowse={true}
        allowPaste={!isTrialExpired}
        dropOnPage={!isTrialExpired}
        allowReplace={true}
        dropOnElement={false}
        onaddfile={(error, file) => handleAddImage(error as any as CustomFilePondErrorDescription, file)}
        credits={false}
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/tiff']}
        files={files}
        disabled={isTrialExpired}
        maxFileSize="25mb"
        labelMaxFileSizeExceeded="File is too large. The limit is 25mb"
      />
    </div>
  );
}
