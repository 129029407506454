import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_KEY, URL_PATH } from 'app/Constants';

export default function ProtectedRoute({
  redirectPath = URL_PATH.LOGIN,
  children,
}: {
  redirectPath?: string;
  children: any;
}) {
  let location = useLocation();
  if (!window.localStorage.getItem(LOCAL_STORAGE_KEY)) {
    return (
      <Navigate
        to={{
          pathname: redirectPath,
        }}
        state={{ from: location }}
        replace
      />
    );
  }
  return children ? children : <Outlet />;
}
