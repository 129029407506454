import { Layout, Spin } from 'antd';
import AiConsoleFrame from 'components/AIConsoleFrame';
import ImageUploader from 'components/ImageUploader';
import { useSelector } from 'react-redux';
import './style.css';
import { selectActiveStaining, selectIsUploadingImage, selectUploadImageId } from 'redux/uploadSlice';

export default function Content() {
  const uploadImageId = useSelector(selectUploadImageId);
  const isUploadingImage = useSelector(selectIsUploadingImage);
  const activeStaining = useSelector(selectActiveStaining);

  return (
    <>
      <Layout id="content">
        {isUploadingImage ? (
          <div id="spin-container">
            <Spin spinning={isUploadingImage} tip="Loading Image..." />
          </div>
        ) : (
          <>
            <ImageUploader />
            {uploadImageId && activeStaining && <AiConsoleFrame />}
          </>
        )}
      </Layout>
    </>
  );
}
