import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd';
import { backendUrl } from 'app/api';
import { URL_PATH } from 'app/Constants';
import { captureApplicationError } from 'app/utils';
import 'components/Authentication/LoginForm/style.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPasswordForAccountConfirmation, setUserInfo, setUsernameForAccountConfirmation } from 'redux/userSlice';

const { Title } = Typography;

type LoginFormProps = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (values: LoginFormProps) => {
    setLoggingIn(true);
    const authenticationUrl = backendUrl('authenticate/');

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['username']);
    bodyFormData.append('password', values['password']);

    fetch(authenticationUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(result => {
        dispatch(
          setUserInfo({
            accessToken: result.token,
            groups: result.groups,
            aiToken: result.ai_token,
            refreshToken: result.refresh_token,
            isGTCAccepted: result.gtc_accepted,
            loginTimestamp: Date.now(),
            labId: result.lab_id,
          }),
        );
        navigate(`../`);
      })
      .catch(err => {
        let errorMessage = 'Error signing in. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }

        if (errorMessage.includes('User has not been confirmed')) {
          dispatch(setUsernameForAccountConfirmation(values['username']));
          dispatch(setPasswordForAccountConfirmation(values['password']));
          navigate(`../${URL_PATH.CONFIRM_ACCOUNT}`);
        }

        setLoggingIn(false);
        setError(true);
        setErrorMessage(errorMessage);
        captureApplicationError(errorMessage);
      });
  };

  const goToResetPassword = () => {
    navigate(`../${URL_PATH.RESET_PASSWORD}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>Log In</Title>
        <Form className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Username"
            name="username"
            className="login-form-item"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              autoComplete="current-username"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            className="login-form-item"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              size="large"
            />
          </Form.Item>
          <Button id="reset-password-link" type="link" onClick={goToResetPassword}>
            Reset password
          </Button>
          <Form.Item className="login-form-item" style={{ clear: 'both' }}>
            <Button
              id="login-button"
              htmlType="submit"
              type="primary"
              style={{ width: '100%', marginTop: 16 }}
              loading={loggingIn}
              size="large"
            >
              {loggingIn ? 'Logging In' : 'Log In'}
            </Button>
          </Form.Item>
          {error && <Alert message="Error signing in" description={errorMessage} type="error" showIcon />}
        </Form>
      </Col>
    </Row>
  );
}
