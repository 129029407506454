import { Button, Divider, Modal, Typography } from 'antd';
import { LOCAL_STORAGE_KEYS } from 'app/Constants';
//@ts-ignore
import quickstartVideo from 'app/assets/video/web-ihc-quickstart.mp4';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsQuickstartModalOpen, setIsQuickstartModalOpen } from 'redux/userSlice';

const { Title, Text } = Typography;

export default function QuickstartModal() {
  const dispatch = useDispatch();

  const isQuickstartModalOpen = useSelector(selectIsQuickstartModalOpen);

  const handleCancel = () => {
    dispatch(setIsQuickstartModalOpen(false));
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.APPLICATION_FIRST_USE, 'true');
  };

  const isApplicationFirstUse = !JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICATION_FIRST_USE) ||
      `{ "${LOCAL_STORAGE_KEYS.APPLICATION_FIRST_USE}": "false" }`,
  );

  return (
    <Modal
      open={isQuickstartModalOpen}
      onCancel={handleCancel}
      width={1140}
      footer={
        isApplicationFirstUse
          ? [
              <Button type="primary" onClick={handleCancel}>
                Continue to Mindpeak WebIHC
              </Button>,
            ]
          : null
      }
      destroyOnClose={true}
    >
      <Title level={3} style={{ marginTop: 0 }}>
        Mindpeak WebIHC Quickstart
      </Title>
      <p>
        To get started quickly with the Mindpeak WebIHC you can try out some demo images by following the steps below:
      </p>
      <ol>
        <li>Click 'View Demo Images'.</li>
        <li>Select a Ki-67 Demo Image.</li>
        <li>To analyse the image, right click with your mouse and draw a selection area.</li>
      </ol>
      <p>You can then adjust the positivity threshold, show/hide the analysis or download the results.</p>
      <div style={{ textAlign: 'center' }}>
        <video width="817" height="430" controls>
          <source src={quickstartVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Divider />
      <p>
        You can also upload and analyse your own images or screenshots - either <b>png</b> or <b>jpg/jpeg</b>.
      </p>
      <p>
        You can upload an image using the following methods:
        <ul>
          <li>Click 'Upload Image' and select an Image file.</li>
          <li>Drag and drop an Image into the WebIHC.</li>
          <li>
            Paste an Image into the WebIHC (<Text keyboard>Ctrl + V</Text> (Windows and Linux) or{' '}
            <Text keyboard>Command ⌘ + V</Text> (Mac/OSX)).
          </li>
        </ul>
      </p>
    </Modal>
  );
}
