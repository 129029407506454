export const AI_TYPE_KI67 = {
  value: 'ki67',
  label: 'Ki-67',
  magnifications: ['20x', '40x'],
};
export const AI_TYPE_ER = {
  value: 'er',
  label: 'ER',
  magnifications: ['20x', '40x'],
};
export const AI_TYPE_PR = {
  value: 'pr',
  label: 'PR',
  magnifications: ['20x', '40x'],
};
export const AI_TYPE_PDL1 = {
  value: 'pdl1',
  label: 'PD-L1',
  magnifications: ['20x'],
};
export const AI_TYPE_HER2 = {
  value: 'her2',
  label: 'HER2',
  magnifications: ['40x'],
};
export const AI_TYPE_P53 = {
  value: 'p53',
  label: 'P53',
  magnifications: ['20x', '40x'],
};
export const AI_TYPE_KI67_NE = {
  value: 'ki67ne',
  label: 'Ki-67 (NE)',
  magnifications: ['20x', '40x'],
};

export const AI_TYPE_OPTIONS = [
  `${AI_TYPE_KI67.value}:${AI_TYPE_KI67.label}`,
  `${AI_TYPE_ER.value}:${AI_TYPE_ER.label}`,
  `${AI_TYPE_PR.value}:${AI_TYPE_PR.label}`,
];

export const USER_GROUP = {
  HER2_ALPHA: 'her2-alpha-users',
  PDL1_ALPHA: 'pdl1-alpha-users',
  KI67NE_ALPHA: 'ki67ne-alpha-users',
  PAID_SINGLE_ACCOUNT: 'paid-single-account-users',
  PAID_ENTERPRISE: 'paid-enterprise-users',
  EXPORT_ALLOWED: 'export-allowed-users',
  KI67NE_ONLY: 'ki67-ne-only',
};

export const LOCAL_STORAGE_KEY = 'web-ihc-user';

export const LOCAL_STORAGE_KEYS = {
  FREE_ACCOUNT_START_DATE: 'FREE_ACCOUNT_START_DATE',
  FREE_ACCOUNT_API_REQUEST_COUNT: 'FREE_ACCOUNT_API_REQUEST_COUNT',
  APPLICATION_FIRST_USE: 'APPLICATION_FIRST_USE',
};

export const FREE_ACCOUNT_LIMIT = {
  API_REQUESTS: 30,
  TIME_IN_DAYS: 30,
};

export const URL_PATH = {
  LOGIN: 'login',
  CREATE_ACCOUNT: 'create-user-account',
  CONFIRM_ACCOUNT: 'confirm-account',
  RESET_PASSWORD: 'reset-password',
};
