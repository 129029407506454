import { Card, Divider } from 'antd';
import { URL_PATH } from 'app/Constants';
import logo from 'app/assets/images/mindpeak_logoform_digital_purple_borderless.png';
import 'components/Authentication/AuthenticationFormContainer/style.css';
import ConfirmAccountForm from 'components/Authentication/ConfirmAccountForm';
import CreateAccountForm from 'components/Authentication/CreateAccountForm';
import LoginForm from 'components/Authentication/LoginForm';
import ResetPassword from 'components/Authentication/ResetPassword';
import UserDetailsForm from 'components/UserDetailsForm';
import { Route, Routes } from 'react-router-dom';

export default function AuthenticationFormContainer() {
  return (
    <>
      <div className="form-container">
        <Card style={{ minWidth: 600 }}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img width={360} src={logo} alt="Mindpeak Logo" style={{ marginBottom: '16px' }} />
          </div>
          <Divider style={{ margin: '0 0 16px 0' }} />
          <Routes>
            <Route path={URL_PATH.LOGIN} element={<LoginForm />} />
            <Route path={URL_PATH.CREATE_ACCOUNT} element={<CreateAccountForm />} />
            <Route path={URL_PATH.CONFIRM_ACCOUNT} element={<ConfirmAccountForm />} />
            <Route path={URL_PATH.RESET_PASSWORD} element={<ResetPassword />} />
          </Routes>
        </Card>
      </div>
      <UserDetailsForm />
    </>
  );
}
