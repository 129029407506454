import { Divider, Modal, Typography } from 'antd';
import { USER_GROUP } from 'app/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsHelpModalOpen, selectUserGroups, setIsHelpModalOpen } from 'redux/userSlice';
import { selectAIScenarioList } from 'redux/selectors';

const { Text, Title } = Typography;

export default function HelpModal() {
  const dispatch = useDispatch();

  const isHelpModalOpen = useSelector(selectIsHelpModalOpen);
  const userGroups = useSelector(selectUserGroups);
  const aiScenarioList = useSelector(selectAIScenarioList);

  const handleCancel = () => {
    dispatch(setIsHelpModalOpen(false));
  };

  return (
    <Modal open={isHelpModalOpen} onCancel={handleCancel} width={1140} footer={null}>
      <Title level={3}>Mindpeak WebIHC Guide</Title>
      <Divider />
      <Title level={4}>Image Upload</Title>
      <p>You can upload an image using the following methods:</p>
      <ul>
        <li>Click the 'Upload Image' button to select an image from your local file system.</li>
        <li>Drag and Drop an image from your local file system.</li>
        <li>
          Copy and Paste an image using <Text keyboard>Ctrl + V</Text> (Windows and Linux) or{' '}
          <Text keyboard>Command ⌘ + V</Text> (Mac).
        </li>
      </ul>
      <Divider />
      <Title level={4}>Image Analysis</Title>
      <p>To Analyse an area of the image, right click with your mouse and draw a selection.</p>
      <p>To select a staining type use the 'Select AI Scenario for Analysis' dropdown.</p>
      You can select which staining has been used in your uploaded image. The WebIHC currently supports the following
      stainings:
      <ul>
        {aiScenarioList.map((aiScenario, index) => (
          <li key={index}>{aiScenario.split(':')[1]}</li>
        ))}
      </ul>
      <Divider />
      <Title level={4}>Results</Title>
      <p>The WebIHC shows several details about the uploaded image:</p>
      <ul>
        <li>Positive Tumor Cells</li>
        <li>Negative Tumor Cells</li>
      </ul>
      <div>
        <Text strong>Ki-67 Only:</Text>
        <ul>
          <li>Proliferation Score (positive tumor cells &#247; total positive and negative tumor cells)</li>
        </ul>
      </div>
      <div>
        <Text strong>ER/PR Only:</Text>
        <ul>
          <li>Positivity Score (positive tumor cells &#247; total positive and negative tumor cells)</li>
          <li>Intensity Score (as per Dako interpretation manual)</li>
          <li>Proportion Score (as per Dako interpretation manual)</li>
          <li>You can adjust the positivity threshold by dragging the slider.</li>
        </ul>
      </div>
      {userGroups.includes(USER_GROUP.PDL1_ALPHA) && (
        <div>
          <Text strong>PD-L1 Only:</Text>
          <ul>
            <li>Tumor Positive Score (TPS) (total positive tumor cells &#247; total viable tumor cells)</li>
            <li>
              Combined Positive Score (CPS) ((total positive tumor + positive relevant immune cells) &#247; total viable
              tumor cells)
            </li>
            <li>Agranular Leukocyte Positive cells</li>
          </ul>
        </div>
      )}
      {userGroups.includes(USER_GROUP.HER2_ALPHA) && (
        <div>
          <Text strong>HER2 Only:</Text>
          <ul>
            <li>HER2 Score</li>
            <li>Complete strong Tumor cells</li>
            <li>Complete weak Tumor cells</li>
          </ul>
        </div>
      )}
      <br />
      <p>You can also toggle the results cells displayed by clicking the 'Show/Hide Analysis Results' switch.</p>
      <Divider />
      <Title level={4}>Adding and removing Cells</Title>
      <div>
        <Text strong>Adding a Cell</Text>
        <br />
        You can add a new cell by double-clicking the left mouse button anywhere on the image.
      </div>
      <div>
        <Text strong>Removing a Cell</Text>
        <br />
        You can remove a cell by double-clicking on it with the left mouse button.
      </div>
      <Divider />
      <Title level={4}>Changing Cell Type (Ki-67 Only)</Title>
      <div>
        You can change the cell type of a specific cell from positive to negative and vice versa by single-clicking on
        it.
      </div>
      <Divider />
    </Modal>
  );
}
