import { configureStore, createAction, Middleware, Reducer } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/react';
import { combineReducers } from 'redux';
import uploadReducer from 'redux/uploadSlice';

import userReducer from 'redux/userSlice';
import { LOCAL_STORAGE_KEY } from 'app/Constants';

const rootReducer = combineReducers({
  user: userReducer,
  upload: uploadReducer,
});

export const clearReduxStore = createAction('clearReduxStore');

const persistLoginMiddleware: Middleware = () => next => action => {
  if (action.type === 'user/setUserInfo') {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(action.payload));
  }

  return next(action);
};

const resettableRootReducer: Reducer = (state, action) => {
  if (action.type === clearReduxStore.type) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(persistLoginMiddleware),
  enhancers: [sentryReduxEnhancer],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
