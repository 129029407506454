import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface UploadSlice {
  isUploadingImage: boolean;
  uploadImageId: string | null;
  uploadImageName: string;
  isDemoImageModalOpen: boolean;
  aiScenario: string | null;
  aiScenariosToDisplay: Array<string>;
  isFilePondFileInputOpen: boolean;
  isStainingSelectionModalOpen: boolean;
  activeStaining: string | null;
}

const initialState = {
  isUploadingImage: false,
  uploadImageId: null,
  uploadImageName: '',
  isDemoImageModalOpen: false,
  aiScenario: null,
  aiScenariosToDisplay: [],
  isFilePondFileInputOpen: false,
  isStainingSelectionModalOpen: false,
  activeStaining: null,
} as UploadSlice;

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setIsUploadingImage: (state, action) => {
      state.isUploadingImage = action.payload;
    },
    setUploadImageId: (state, action) => {
      state.uploadImageId = action.payload;
    },
    setUploadImageName: (state, action) => {
      state.uploadImageName = action.payload;
    },
    setIsDemoImageModalOpen: (state, action) => {
      state.isDemoImageModalOpen = action.payload;
    },
    setAIScenario: (state, action) => {
      state.aiScenario = action.payload;
    },
    setAIScenariosToDisplay: (state, action) => {
      state.aiScenariosToDisplay = action.payload;
    },
    setIsFilePondFileInputOpen: (state, action) => {
      state.isFilePondFileInputOpen = action.payload;
    },
    setIsStainingSelectionModalOpen: (state, action) => {
      state.isStainingSelectionModalOpen = action.payload;
    },
    setActiveStaining: (state, action: PayloadAction<string | null>) => {
      state.activeStaining = action.payload;
    },
  },
});

export default uploadSlice.reducer;

export const {
  setIsUploadingImage,
  setUploadImageId,
  setUploadImageName,
  setIsDemoImageModalOpen,
  setAIScenario,
  setAIScenariosToDisplay,
  setIsFilePondFileInputOpen,
  setIsStainingSelectionModalOpen,
  setActiveStaining,
} = uploadSlice.actions;

export const selectIsUploadingImage = (state: RootState) => state.upload.isUploadingImage;
export const selectUploadImageId = (state: RootState) => state.upload.uploadImageId;
export const selectUploadImageName = (state: RootState) => state.upload.uploadImageName;
export const selectIsDemoImageModalOpen = (state: RootState) => state.upload.isDemoImageModalOpen;
export const selectAIScenario = (state: RootState) => state.upload.aiScenario;
export const selectAIScenariosToDisplay = (state: RootState) => state.upload.aiScenariosToDisplay;
export const selectIsFilePondInputOpen = (state: RootState) => state.upload.isFilePondFileInputOpen;
export const selectIsStainingSelectionModalOpen = (state: RootState) => state.upload.isStainingSelectionModalOpen;
export const selectActiveStaining = (state: RootState) => state.upload.activeStaining;
