import { notification } from 'antd';
import { setIsUploadingImage, setUploadImageId, setUploadImageName } from 'redux/uploadSlice';
import { AppDispatch, store } from 'redux/store';
import { selectAccessToken, setIsReAuthDialogShown } from 'redux/userSlice';
import { captureApplicationError } from './utils';
import { ActualFileObject } from 'filepond';

export const backendUrl = (endpoint: string) => {
  const base = process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8000';
  return new URL(`${base}/api/v1/${endpoint}`);
};

export const uploadImage = (
  dispatch: AppDispatch,
  image: ActualFileObject,
  imageDisplayName: string,
  accessToken: string,
  onSuccessCallback: (() => void) | null,
  onErrorCallback: (() => void) | null,
) => {
  dispatch(setUploadImageName(imageDisplayName));

  const uploadUrl = backendUrl('upload');
  fetch(uploadUrl, {
    method: 'POST',
    body: image,
    headers: {
      Authorization: `Mind_Peak_Bearer ${accessToken}`,
      filename: image.name,
    },
  })
    .then(response => {
      if (!response.ok) {
        switch (response.status) {
          case 404:
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          case 500:
            return Promise.resolve(response.json()).then(errorJson => {
              return Promise.reject(errorJson);
            });
          case 401:
            dispatch(setIsReAuthDialogShown(true));
            return Promise.reject('401 Unauthorised');
          default:
            throw Error(response.statusText);
        }
      }
      return response.json();
    })
    .then(result => {
      if (!imageDisplayName.includes('Demo Image #')) {
        notification['success']({
          message: `${imageDisplayName} uploaded successfully.`,
        });
      }

      const uploadImageId = result.id;
      dispatch(setIsUploadingImage(false));
      dispatch(setUploadImageId(uploadImageId));
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    })
    .catch(err => {
      let uploadError = err.toString();
      if (uploadError.includes('Failed to fetch')) {
        dispatch(setIsReAuthDialogShown(true));
        uploadError = 'Authorisation Expired. Please log in again.';
      }

      captureApplicationError(uploadError);
      notification['error']({
        message: `${imageDisplayName} file upload failed.`,
        description: uploadError,
      });
      dispatch(setIsUploadingImage(false));
      if (onErrorCallback) {
        onErrorCallback();
      }
    });
};

const getAuthorization = () => {
  const state = store.getState();
  const authToken = selectAccessToken(state);
  return `Mind_Peak_Bearer ${authToken}`;
};

export const postRequestPromise = (url: URL, bodyFormData: FormData) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: getAuthorization(),
        },
        body: bodyFormData,
      });

      if (response.status === 401) {
        store.dispatch(setIsReAuthDialogShown(true));
      }

      resolve(response);
    } catch (error: any) {
      reject(error);
      captureApplicationError(error.toString());
    }
  }) as Promise<Response>;
