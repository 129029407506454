import App from 'App';
import AuthenticationFormContainer from 'components/Authentication/AuthenticationFormContainer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<AuthenticationFormContainer />} />
      <Route element={<ProtectedRoute children={undefined} />}>
        <Route path="/" element={<App />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
