import { FileImageOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import './style.css';

const { Text } = Typography;

export default function UploadLabel({ isTrialExpired }: { isTrialExpired: boolean }) {
  return isTrialExpired ? (
    <Result
      icon={<FileImageOutlined />}
      title="Your free trial has expired"
      subTitle="Please upgrade to continue using Mindpeak WebIHC"
    />
  ) : (
    <Result
      icon={<FileImageOutlined id="image-uploader-label-icon" />}
      title="Click or drag a Image file to this area to upload"
      subTitle={
        <p>
          You can also copy and paste an Image using <Text keyboard>Ctrl + V</Text> (Windows and Linux) or{' '}
          <Text keyboard>Command ⌘ + V</Text> (Mac).
        </p>
      }
    />
  );
}
