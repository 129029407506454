import { notification } from 'antd';
import { uploadImage } from 'app/api';
import { AI_TYPE_KI67_NE, LOCAL_STORAGE_KEY, USER_GROUP } from 'app/Constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import {
  selectAIScenario,
  selectAIScenariosToDisplay,
  selectUploadImageId,
  selectUploadImageName,
  setActiveStaining,
  setIsStainingSelectionModalOpen,
  setIsUploadingImage,
} from 'redux/uploadSlice';
import {
  incrementAPIRequestCount,
  selectAccessToken,
  selectLabId,
  selectParsedAccessToken,
  selectUserGroups,
} from 'redux/userSlice';
import { selectAIScenarioList } from 'redux/selectors';
import { captureApplicationError } from 'app/utils';

const aiConsoleUrl = process.env.REACT_APP_AI_CONSOLE_URL || 'http://localhost:4200';

const AiConsoleFrame = () => {
  const dispatch = useDispatch();
  const mindpeakToken = useSelector(selectAccessToken);
  const aiToken = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}').aiToken;
  const uploadImageId = useSelector(selectUploadImageId);
  const aiScenario = useSelector(selectAIScenario);
  const aiScenariosToDisplay = useSelector(selectAIScenariosToDisplay);
  const uploadImageName = useSelector(selectUploadImageName);
  const aiScenarioList = useSelector(selectAIScenarioList);
  const labId = useSelector(selectLabId);
  const userGroups = useSelector(selectUserGroups);
  const parsedAccessToken = useSelector(selectParsedAccessToken);

  const queryParamsObject: QueryParams = {
    user_auth_token: mindpeakToken,
    image_id: uploadImageId,
    ai_scenario: 'ki67',
    reader_url: `${process.env.REACT_APP_READER_URL}`,
    storage_payload: JSON.stringify({}),
    ai_models_url: `${process.env.REACT_APP_AI_MODELS_URL}`,
    ai_token: aiToken,
    ai_api_key: `${process.env.REACT_APP_API_KEY}`,
    ai_user_id: `web-ihc_${parsedAccessToken?.username}`,
    origin_url: window.location.origin,
    ai_scenario_to_display: aiScenariosToDisplay,
    image_id_to_display: uploadImageName.replace('#', '%23'),
    ic_ai_enabled: 'true',
    ic_edit_dots: 'true',
    ic_mp_logo_visible: 'false',
    ic_hide_layout: 'user_score_input,user_score_select',
    ic_results_download: 'true',
    ic_upload: 'true',
    disable_mpp_scaling: 'true',
  };

  if (aiScenario) {
    queryParamsObject.ai_scenario = aiScenario;
  }

  if (userGroups.includes(USER_GROUP.EXPORT_ALLOWED)) {
    queryParamsObject.ic_results_json_export = process.env.REACT_APP_ENABLE_RESULTS_JSON_EXPORT;
  }

  if (userGroups.includes(USER_GROUP.KI67NE_ONLY)) {
    queryParamsObject.ai_scenario = AI_TYPE_KI67_NE.value;
    queryParamsObject.ai_scenario_to_display = JSON.stringify([`${AI_TYPE_KI67_NE.value}:${AI_TYPE_KI67_NE.label}`]);
  }

  if (labId) {
    queryParamsObject.ai_lab_id = labId;
  }

  const queryParams = new URLSearchParams(queryParamsObject);

  useEffect(() => {
    const trustedOrigins = [aiConsoleUrl];

    const onMessage = (msg: MessageEvent) => {
      if (!trustedOrigins.includes(msg.origin)) return;
      const imageFile = msg.data;

      if (!(imageFile instanceof File)) return;

      if (imageFile.size > 26214400) {
        captureApplicationError('Drag and Drop - File too large');
        notification['error']({
          message: `${imageFile.name} file upload failed.`,
          description: 'File is too large. The limit is 25mb.',
        });
        dispatch(setIsUploadingImage(false));
        return;
      }

      dispatch(setActiveStaining(null));

      dispatch(setIsUploadingImage(true));

      uploadImage(dispatch, imageFile, imageFile.name, mindpeakToken, null, null);
      dispatch(incrementAPIRequestCount());

      dispatch(setIsStainingSelectionModalOpen(true));
      dispatch(setIsUploadingImage(false));
    };

    window.addEventListener('message', onMessage, false);

    return () => {
      // Clean up when unmounting component.
      window.removeEventListener('message', onMessage);
    };
  }, [uploadImageId, dispatch, mindpeakToken, aiScenarioList]);

  if (!uploadImageId) {
    return null;
  }

  /* NOTE: In order not to cache the iframe in the browser, we are setting the name attribute to the current date. */
  return (
    <iframe title="ai-console" name={new Date().toISOString()} src={`${aiConsoleUrl}/?${queryParams.toString()}`} />
  );
};

export default AiConsoleFrame;
