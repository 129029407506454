import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, notification, Row, Tooltip, Typography } from 'antd';
import { backendUrl } from 'app/api';
import { captureApplicationError } from 'app/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectPasswordForAccountConfirmation,
  selectUsernameForAccountConfirmation,
  setUserInfo,
} from 'redux/userSlice';
import './style.css';

const { Title } = Typography;

type ConfirmAccountFormProps = {
  username: string;
  confirmation_code: string;
};

export default function ConfirmAccountForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usernameForAccountConfirmation = useSelector(selectUsernameForAccountConfirmation);
  const passwordForAccountConfirmation = useSelector(selectPasswordForAccountConfirmation);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationUsername, setConfirmationUsername] = useState(usernameForAccountConfirmation);
  const [newConfirmationCodeErrorMessage, setNewConfirmationCodeErrorMessage] = useState('');

  const handleRequestNewCode = () => {
    let bodyFormData = new window.FormData();
    bodyFormData.append('username', confirmationUsername);

    const requestNewConfirmationCodeUrl = backendUrl('request_new_confirmation_code/');
    fetch(requestNewConfirmationCodeUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(() => {
        notification['info']({
          key: 'requestNewCode',
          message: 'New confirmation code requested',
          description: 'Please check your email',
        });
      })
      .catch(err => {
        let errorMessage = 'Error requesting new code. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }
        captureApplicationError(`error requesting new code - ${errorMessage}`);
        setIsLoading(false);
        setNewConfirmationCodeErrorMessage(errorMessage);
      });
  };

  const handleConfirmAccount = (values: ConfirmAccountFormProps) => {
    setIsLoading(true);

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['username']);
    bodyFormData.append('confirmation_code', values['confirmation_code']);
    bodyFormData.append('password', passwordForAccountConfirmation);

    const confirmAccountCreateUrl = backendUrl('confirm_account_create/');
    fetch(confirmAccountCreateUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            return Promise.reject('Error connecting to WebIHC Server. Please try again later.');
          }
          return response.json().then(json => {
            return Promise.reject(json);
          });
        }
        return response.json();
      })
      .then(result => {
        if (result) {
          dispatch(
            setUserInfo({
              accessToken: result.token,
              groups: result.groups,
              aiToken: result.ai_token,
              refreshToken: result.refresh_token,
              isGTCAccepted: result.gtc_accepted,
              loginTimestamp: Date.now(),
            }),
          );
        }
        notification['info']({
          key: 'confirmSuccess',
          message: 'Account confirmed',
        });
        navigate(`../`);
      })
      .catch(err => {
        let errorMessage = 'Error confirming account. Please try again';
        if (err.details) {
          errorMessage = err.details;
        }
        captureApplicationError(`error confirming account - ${errorMessage}`);
        setIsLoading(false);
        setErrorMessage(errorMessage);
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>Confirm Account</Title>
        <Form
          className="confirm-account-form"
          initialValues={{ remember: true }}
          onFinish={handleConfirmAccount}
          onValuesChange={(changedValues, allValues) => {
            setConfirmationUsername(allValues.username);
          }}
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            className="confirm-account-form-item"
            rules={[{ required: true, message: 'Username is required to confirm account' }]}
            initialValue={confirmationUsername}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              size="large"
              autoComplete="new-password"
              disabled={usernameForAccountConfirmation}
            />
          </Form.Item>
          <Form.Item>
            <Alert
              type="info"
              message="You have been sent an email containing your confirmation code"
              description="Please check your email."
              showIcon
            />
          </Form.Item>
          <Form.Item
            label="Confirmation Code"
            name="confirmation_code"
            rules={[
              {
                required: true,
                message: 'Please input your confirmation code from the email.',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Confirmation Code"
              size="large"
            />
          </Form.Item>
          <Form.Item style={{ clear: 'both' }}>
            <Button
              id="submit-confirm-button"
              htmlType="submit"
              type="primary"
              style={{ width: '100%', marginTop: 16 }}
              loading={isLoading}
              size="large"
              disabled={!confirmationUsername}
            >
              Confirm account
            </Button>
          </Form.Item>
          <Divider />
          {errorMessage && (
            <>
              <Alert message="Error confirming account" description={errorMessage} type="error" showIcon />
              <Divider />
            </>
          )}
          <Form.Item>
            <Alert
              type="info"
              message="Lost your code?"
              description={
                <>
                  {confirmationUsername ? (
                    <Button
                      id="request-new-confirmation-code-button"
                      type="link"
                      onClick={handleRequestNewCode}
                      disabled={!confirmationUsername}
                    >
                      Click here to resend your code
                    </Button>
                  ) : (
                    <Tooltip title="You must input a username above before you can request a new confirmation code">
                      <Button id="request-new-confirmation-code-button" type="link" disabled={!confirmationUsername}>
                        Click here to resend your code
                      </Button>
                    </Tooltip>
                  )}

                  {newConfirmationCodeErrorMessage && (
                    <Alert
                      message="Error resending code"
                      description={newConfirmationCodeErrorMessage}
                      type="error"
                      showIcon
                    />
                  )}
                </>
              }
              showIcon
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
